import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { call } from "../../../api/apiCall";
import { toast } from "react-toastify";

export default function ReportModal(props) {
  const { modal, toggle, typeId } = props;

  const [confirmModal, setConfirmModal] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [reportId, setReportId] = useState(0);
  const [isOthers, setIsOthers] = useState(false);
  const [otherReason, setOtherReason] = useState("");
  const [reportData, setReportData] = useState([]);
  const dispatch = useDispatch();

  const getReportType = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const res = await call("get", `api/v1/report-type`, null, null, token);
      if (res.data.success === true) {
        setReportData(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getReportType();
  }, []);

  const navigate = useNavigate();

  const reportSubmit = async () => {
    try {
      let token = localStorage.getItem("accessToken");
      let data = {
        report_type_id: reportId,
        type: "worker_profile",
        type_id: typeId,
        message: reportId ? reportReason : otherReason,
        report_type: reportId ? "" : otherReason,
      };

      const res = await call(
        "post",
        `api/v1/feed/report-post`,
        null,
        data,
        token
      );
      if (res.data.success === true) {
        toast.success("Reported Successfully");
        toggle();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = () => {
    if (reportReason || otherReason) {
      reportSubmit();
    } else alert("Please select reason for report");
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="add-section report-modal fix-width gradient-background"
        centered
      >
        <ModalHeader style={{ padding: "20px !important" }} toggle={toggle}>
          Why you are reporting this Worker Profile?
        </ModalHeader>
        <ModalBody>
          <div className="radios">
            {reportData?.data?.map((data, index) => {
              return (
                <div className="d-flex align-items-center my-2" key={index}>
                  <input
                    className="me-2 mt-1"
                    style={{ height: "15px", width: "15px" }}
                    type="radio"
                    id="html"
                    name="reportReason"
                    value={data?.type}
                    onClick={() => {
                      setReportReason(data?.type);
                      setReportId(data?.id);
                    }}
                    required
                  />
                  <label className="text-capitalize" htmlFor="html">
                    {data?.type}
                  </label>
                  {/* <FormGroup check >
                  <Label check style={{ color: "#717171"}}>
                    <Input type="radio" name="radio2" value={data?.type} className="text-capitalize" />
                    {data?.type}
                  </Label>
                </FormGroup> */}
                </div>
              );
            })}
            <div className="my-2">
              <div className="d-flex align-items-center">
                <input
                  className="me-2 mt-1"
                  style={{ height: "15px", width: "15px" }}
                  type="radio"
                  id="html"
                  name="reportReason"
                  value={otherReason}
                  onClick={() => {
                    setIsOthers(true);
                    setReportReason("");
                    setReportId(null);
                  }}
                  required
                />
                <label className="text-capitalize" htmlFor="html">
                  Others
                </label>
              </div>

              {isOthers && (
                <div className="other-reson my-3">
                  <textarea
                    rows={5}
                    className="w-100 p-2"
                    type="text"
                    maxLength={50}
                    onChange={(e) => setOtherReason(e.target.value)}
                    placeholder="Please enter you reason"
                  />
                </div>
              )}
              {/* <FormGroup check >
                  <Label check style={{ color: "#717171"}}>
                    <Input type="radio" name="radio2" value={data?.type} className="text-capitalize" />
                    {data?.type}
                  </Label>
                </FormGroup> */}
            </div>
          </div>

          <div className="text-center mt-3">
            <button onClick={() => handleSubmit()} className={"btn btn-info"}>
              Report
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
